
import { defineComponent, ref } from "vue";
import { useRouter, onBeforeRouteLeave } from "vue-router";
import { debounce } from "lodash-es";
import moment from "moment";
import { useStore } from "vuex";
import {
  getLocalDealerByName,
  getLocalDealerByCode,
  getLocalDealerBySwtCode,
  getDealerMainData,
  applicableTypeClassList,
  checkDeleteDealerCauseBlank,
  saveBaseInfo,
  queryBlankDealer,
} from "@/API/localRegistration";
import { message } from "ant-design-vue";
export default defineComponent({
  name: "Focus Area Set Dealer Adjustment Delete",
  setup() {
    const store = useStore();
    const currentUserBu: string = store.state.user.bu.orgnmen;
    const router: any = useRouter();
    const dealerNameOptions = ref<any[]>([]);
    const dealerCodeOptions = ref<any[]>([]);
    const swtCodeOptions = ref<string[]>([]);
    const fetching = ref<boolean>(false);
    const dealerName = ref<string>("");
    const dealerCode = ref<string>("");
    const dealerId = ref<string>("");
    const province = ref<string>("");
    const city = ref<string>("");
    const createdAt = ref("");
    const createdBy = ref("");
    const region = ref<string>("");
    const swtCode = ref<string>("");
    const dealerGroup = ref<string>("");
    const typeClassInfo = ref<any[]>([]);
    const typeClassInfoCopy = ref<any[]>([]);
    const isBlankCity = ref<boolean>(false);

    const typeClassList = ref<any[]>([]);
    const typeClass = ref<string[]>([]);
    const preTypeClass = ref<string[]>([]);

    const unCheckList = ref<any[]>([]);
    const blankList = ref<string[]>([]);

    const specialCitys = ref<any[]>([]);

    const handlerDealerNameFocus = () => {
      const params = {
        dealerName: "",
      };
      getLocalDealerByName(params).then((res: any) => {
        dealerNameOptions.value = res.data.map((item: any) => {
          return {
            label: item.dealerName,
            value: item.dealerName,
            city: item.city,
            dealerCode: item.dealerCode,
            dealerName: item.dealerName,
            province: item.province,
            swtCode: item.swtCode,
            dealerGroup: item.dealerGroup,
            dealerId: item.id,
          };
        });
      });
    };

    const handleGetDealerOptions = debounce((value) => {
      dealerNameOptions.value = [];
      fetching.value = true;
      const params = {
        dealerName: value,
      };
      getLocalDealerByName(params).then((res: any) => {
        dealerNameOptions.value = res.data.map((item: any) => {
          return {
            label: item.dealerName,
            value: item.dealerName,
            city: item.city,
            dealerCode: item.dealerCode,
            dealerName: item.dealerName,
            province: item.province,
            swtCode: item.swtCode,
            dealerGroup: item.dealerGroup,
            dealerId: item.id,
          };
        });
      });
    }, 300);

    const handleGetDealerCodeOptions = debounce((value) => {
      dealerCodeOptions.value = [];
      fetching.value = true;
      const params = {
        dealerCode: value,
      };
      getLocalDealerByCode(params).then((res: any) => {
        dealerCodeOptions.value = res.data.map((item: any) => {
          return {
            label: item.dealerCode,
            value: item.dealerCode,
            city: item.city,
            dealerName: item.dealerName,
            dealerCode: item.dealerCode,
            province: item.province,
            swtCode: item.swtCode,
            dealerGroup: item.dealerGroup,
            dealerId: item.id,
          };
        });
      });
    }, 300);

    const handleGetSwtCodeOptions = debounce((value) => {
      swtCodeOptions.value = [];
      fetching.value = true;
      const params = {
        swtCode: value,
      };
      getLocalDealerBySwtCode(params).then((res: any) => {
        swtCodeOptions.value = res.data.map((item: any) => {
          return {
            label: item.swtCode,
            value: item.swtCode,
            city: item.city,
            dealerName: item.dealerName,
            dealerCode: item.dealerCode,
            province: item.province,
            swtCode: item.swtCode,
            dealerGroup: item.dealerGroup,
            dealerId: item.id,
          };
        });
      });
    }, 300);

    const handleDealerChange = (value: any, dealer: any) => {
      dealerId.value = dealer.dealerId;
      dealerName.value = dealer.dealerName;
      dealerCode.value = dealer.dealerCode;
    };

    const handleSearch = () => {
      const params = {
        dealerId: dealerId.value,
      };
      getDealerMainData(params).then((res: any) => {
        res.data.applicableVOS.forEach((item: any) => {
          item.isExpand = true;
          item.effectiveTime = moment(new Date());
        });
        specialCitys.value = res.data.specialAreas.map(
          (item: any) => item.city
        );
        province.value = res.data.basicInfo.province;
        city.value = res.data.basicInfo.city;
        createdAt.value = res.data.basicInfo.createdAt;
        createdBy.value = res.data.basicInfo.createdBy;
        region.value = res.data.basicInfo.region;
        swtCode.value = res.data.basicInfo.swtCode;
        dealerGroup.value = res.data.basicInfo.dealerGroup;
        typeClassInfo.value = res.data.applicableVOS;
        preTypeClass.value = typeClassInfo.value.map(
          (item) => item.applicableTypeClass
        );
        typeClassInfoCopy.value = JSON.parse(
          JSON.stringify(res.data.applicableVOS)
        );
        typeClass.value = typeClassInfo.value.map(
          (item) => item.applicableTypeClass
        );
        typeClassList.value.forEach((typeClassItem) => {
          typeClass.value.forEach((l) => {
            if (typeClassItem.value === l) {
              typeClassItem.isDisabled = false;
            }
          });
        });
        isBlankCity.value = false;
      });
    };

    const handleTypeClassChange = (value: string[]) => {
      let currentTypeClass: string;
      if (preTypeClass.value.length > value.length) {
        // 取消勾选
        currentTypeClass = preTypeClass.value.filter(
          (item) => !value.includes(item)
        )[0];
        unCheckList.value.push(
          typeClassInfo.value.filter(
            (item) => item.applicableTypeClass == currentTypeClass
          )[0]
        );
        typeClassInfo.value = typeClassInfo.value.filter(
          (item) => item.applicableTypeClass != currentTypeClass
        );

        const params = {
          applicableTypeClass: currentTypeClass,
          region: typeClassInfoCopy.value.filter(
            (item) => item.applicableTypeClass == currentTypeClass
          )[0].region,
          province: typeClassInfoCopy.value.filter(
            (item) => item.applicableTypeClass == currentTypeClass
          )[0].province,
          city: typeClassInfoCopy.value.filter(
            (item) => item.applicableTypeClass == currentTypeClass
          )[0].city,
        };
        checkDeleteDealerCauseBlank(params).then((res: any) => {
          isBlankCity.value = res.data;
          if (res.data) {
            blankList.value.push(currentTypeClass);
          }
        });
      } else {
        // 新增勾选
        currentTypeClass = value.filter(
          (item) => !preTypeClass.value.includes(item)
        )[0];
        typeClassInfo.value.push(
          typeClassInfoCopy.value.filter(
            (item) => item.applicableTypeClass == currentTypeClass
          )[0]
        );
        unCheckList.value = unCheckList.value.filter(
          (item) => item.applicableTypeClass != currentTypeClass
        );
        isBlankCity.value = false;
      }
      preTypeClass.value = value;
    };

    const genSaveParams = () => {
      const checkList = [...new Set(blankList.value)];
      const applicables = unCheckList.value.map((item) => {
        return {
          id: item.id,
          applicableTypeClass: item.applicableTypeClass,
          region: item.region,
          subRegion: item.subRegion,
          status: "1",
          effectiveTime:
            moment(item.effectiveTime).format("YYYY-MM-DD") === "Invalid date"
              ? null
              : moment(item.effectiveTime).format("YYYY-MM-DD"),
        };
      });
      const params = {
        operationType: "delete",
        specialAreas: specialCitys.value.map((item) => {
          return {
            city: item,
          };
        }),
        basicInfo: {
          dealerCode: dealerCode.value,
          dealerName: dealerName.value,
          swtCode: swtCode.value,
          province: province.value,
          city: city.value,
          dealerGroup: dealerGroup.value,
          id: dealerId.value,
          createdAt: createdAt.value,
          createdBy: createdBy.value,
        },
        checkList,
        applicables,
      };
      return params;
    };

    const handleSave = () => {
      const params = genSaveParams();
      saveBaseInfo(params).then((res) => {
        message.success("You have deleted dealer successfully");
      });
    };

    const handleNext = () => {
      const saveParams = genSaveParams();
      const params = {
        applicableTypeClass: unCheckList.value.map(
          (item) => item.applicableTypeClass
        ),
        city: city.value,
        dealerCode: dealerCode.value,
        province: province.value,
        swtCode: swtCode.value,
      };
      queryBlankDealer(params).then((res: any) => {
        const checkList = typeClassInfo.value.map(
          (item) => item.applicableTypeClass
        );
        const query = {
          city: city.value,
          dealerName: dealerName.value,
          dealerCode: dealerCode.value,
          dealerId: dealerId.value,
          swtCode: swtCode.value,
          tableData: JSON.stringify(res),
          checkList: JSON.stringify(checkList),
          saveParams: JSON.stringify(saveParams),
          typeClassInfo: JSON.stringify(typeClassInfo.value),
        };
        router.push({
          name: "Focus Area Set Dealer Adjustment Delete2",
          query: query,
        });
      });
    };

    const init = () => {
      applicableTypeClassList().then((res: any) => {
        typeClassList.value = res.map((item: string) => {
          return {
            value: item,
            label: item,
            isDisabled: true,
          };
        });
      });
    };
    init();

    onBeforeRouteLeave((to) => {
      if (to.name === "Focus Area Set Dealer Adjustment Delete2") {
        store.commit(
          "addKeepAliveComponentName",
          "Focus Area Set Dealer Adjustment Delete"
        );
      } else {
        store.commit(
          "removeKeepAliveComponentName",
          "Focus Area Set Dealer Adjustment Delete"
        );
      }
    });

    return {
      dealerName,
      dealerNameOptions,
      dealerCodeOptions,
      fetching,
      handleGetDealerOptions,
      handlerDealerNameFocus,
      handleGetDealerCodeOptions,
      swtCodeOptions,
      handleGetSwtCodeOptions,
      typeClassInfo,
      typeClassList,
      typeClass,
      isBlankCity,
      handleDealerChange,
      handleSearch,
      handleTypeClassChange,
      handleNext,
      handleSave,
      dealerCode,
      province,
      city,
      swtCode,
    };
  },
});
